<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor});`"
  >
    <LibraryPopupsVideoPopup
      :videoUrl="vars.videoUrl"
      v-model:is-visible="isVisible"
    />
    <!-- <MotionGroup :preset="vars.animationText ? vars.animationText : 'fadeVisible'" :duration="800"> -->
    <div v-if="variants.v1 === variant">
      <div
        class="tw-grid tw-max-w-xl tw-gap-3 xl:tw-max-w-none xl:tw-grid-cols-12 xl:tw-items-center xl:tw-gap-x-24"
        :style="`color: var(--c-${vars.textColor})`"
      >
        <NuxtImg
          v-if="vars.photoImage && !vars.videoUrl"
          :src="vars.photoImage"
          :alt="vars.titleText"
          class="tw-w-full tw-self-center tw-rounded-xl tw-object-cover xl:tw-col-span-5 xl:tw-row-span-2"
          :class="{
            'xl:tw-col-start-8 xl:tw-row-start-1': vars.isReversedBool,
          }"
        />
        <div
          v-if="vars.videoUrl"
          class="tw-w-full tw-self-center tw-rounded-xl tw-object-cover xl:tw-col-span-5 xl:tw-row-span-2"
          :class="{
            'xl:tw-col-start-8 xl:tw-row-start-1': vars.isReversedBool,
          }"
        >
          <library-video-embed
            :video-url="vars.videoUrl"
            :title="vars.mainTitleText"
            :is-popup="true"
            :cover-photo="vars.photoImage"
          />
        </div>
        <h3
          class="tw-order-first tw-flex tw-flex-col tw-gap-4 xl:tw-order-none xl:tw-col-span-7 xl:tw-self-end"
        >
          {{ vars.titleText }}
        </h3>
        <p
          class="tw-flex tw-flex-col tw-gap-4 xl:tw-col-span-7 xl:tw-self-start"
          v-for="(item, index) in descriptions"
          :key="`content-box-description-${index}`"
        >
          {{ item.description }}
        </p>
      </div>
    </div>
    <div v-if="variants.v2 === variant">
      <div
        class="tw-grid tw-max-w-xl tw-gap-3 lg:tw-max-w-2xl xl:tw-max-w-none xl:tw-grid-cols-12 xl:tw-items-center xl:tw-gap-x-24"
        :style="`color: var(--c-${vars.textColor})`"
      >
        <div
          class="tw-w-full tw-self-center tw-overflow-hidden tw-rounded-xl tw-object-cover xl:tw-col-span-5 xl:tw-row-span-2"
          :class="{
            'xl:tw-col-start-8 xl:tw-row-start-1': vars.isReversedBool,
            '!xl:tw-col-span-5': vars.isVerticalBool,
            'xl:tw-col-start-9': vars.isVerticalBool && vars.isReversedBool,
          }"
        >
          <LibraryImage
            v-if="vars.photoImage && !vars.videoUrl"
            :src="vars.photoImage"
            :w-ratio="vars.isVerticalBool ? 9 : 645"
            :h-ratio="vars.isVerticalBool ? 16 : 430"
            :alt="plainText"
          />
          <div
            v-if="vars.photoImage && vars.videoUrl"
            class="tw-relative tw-w-full"
            @click="isVisible = true"
          >
            <LibraryImage
              :src="vars.photoImage"
              :alt="vars.titleText"
              class="tw-w-full tw-cursor-pointer"
              :w-ratio="vars.isVerticalBool ? 9 : 645"
              :h-ratio="vars.isVerticalBool ? 16 : 430"
              style="border-radius: var(--rounded-sm)"
            />
            <button
              class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
            >
              <svg
                width="80"
                height="80"
                viewBox="0 0 162 162"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="81" cy="81" r="81" fill="white" />
                <polygon points="65,45 115,81 65,117" fill="black" />
              </svg>
            </button>
          </div>
        </div>
        <div
          class="tw-flex tw-flex-col tw-gap-4 xl:tw-order-none xl:tw-col-span-7 xl:tw-self-end"
          :class="{
            'xl:tw-col-span-8': vars.isVerticalBool && vars.isReversedBool,
          }"
          v-html="vars.titleHtml"
        ></div>
        <p
          class="tw-flex tw-flex-col tw-gap-4 xl:tw-col-span-7 xl:tw-self-start"
          :class="{
            'xl:tw-col-span-8': vars.isVerticalBool && vars.isReversedBool,
          }"
          v-for="(item, index) in descriptions"
          :key="`content-box-description-${index}`"
        >
          {{ item.description }}
        </p>
      </div>
    </div>

    <!-- </MotionGroup> -->
  </section>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContentBox',

  mixins: [ComponentMixin],

  data() {
    return {
      plainText: '',
      isVisible: false,
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  mounted() {
    this.plainText = this.getTextContent(this.vars.titleHtml);
  },

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },

  methods: {
    getTextContent(htmlString) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlString;
      return tempDiv.textContent || tempDiv.innerText || '';
    },
  },
});
</script>
