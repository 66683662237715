<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      class="tw-grid tw-grid-cols-12 tw-items-center tw-justify-center tw-gap-6 lg:tw-gap-12"
    >
      <div class="tw-col-span-12 lg:tw-col-span-7">
        <div
          class="tw-border tw-bg-white tw-px-6 tw-py-10 lg:tw-px-14 lg:tw-py-16"
          style="border-radius: var(--rounded); border-color: var(--c-border)"
        >
          <library-form
            v-if="form"
            :form="form"
            version="v2"
            :component="component"
          ></library-form>
        </div>
      </div>
      <div class="tw-col-span-12 lg:tw-col-span-5">
        <NuxtImg :src="vars.photoImage" style="border-radius: var(--rounded)" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContactFormAndImage',

  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
    };
  },

  created() {
    this.form = this.parseFormV2(this.vars.contactForm);
  },
});
</script>
