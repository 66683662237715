<template>
  <section
    class="n-section-secondary"
    :style="`background-color: var(--c-${vars.backgroundColor}); `"
  >
    <h4 :style="`color:var(--c-${vars.textColor})`" class="tw-text-center">
      {{ vars.titleText }}
    </h4>
    <div
      class="tw-flex tw-w-full tw-flex-col tw-text-white xl:tw-flex-row xl:tw-justify-center"
      :style="`color:var(--c-${vars.textColor})`"
    >
      <div
        v-for="(count, index) in counts"
        :key="`countsection-count-${index}`"
        class="tw-group tw-relative tw-flex tw-flex-col tw-items-center tw-justify-start tw-break-all tw-py-10 tw-text-center xl:tw-w-full xl:after:tw-right-0 2xl:tw-pt-24"
      >
        <h1
          class="tw-whitespace-nowrap tw-duration-300 group-hover:-tw-translate-y-1"
        >
          {{ count.title }}
        </h1>
        <p
          class="tw-mx-6 tw-min-h-[48px] tw-duration-300 group-hover:-tw-translate-y-1"
        >
          {{ count.description }}
        </p>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'CountSection',

  mixins: [ComponentMixin],

  computed: {
    counts() {
      return this.groupedVariables.counts;
    },
  },

  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.animateCounts();
        }
      });
    });

    observer.observe(this.$el);
  },

  methods: {
    animateCounts() {
      this.groupedVariables.counts.forEach((count, index) => {
        const element = this.$el.querySelectorAll('h1')[index];
        this.animateValue(
          element,
          0,
          count.title,
          2000,
          count.prefix,
          count.suffix,
        );
      });
    },
    animateValue(element, start, end, duration, prefix = '', suffix = '') {
      let startTime = null;

      const easeOutQuad = (t, b, c, d) => {
        t /= d;
        return -c * t * (t - 2) + b;
      };

      const animate = (currentTime) => {
        if (!startTime) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = easeOutQuad(timeElapsed, start, end - start, duration);
        element.innerText = `${prefix}${Math.floor(run)}${suffix}`;
        if (timeElapsed < duration) {
          requestAnimationFrame(animate);
        } else {
          element.innerText = `${prefix}${end}${suffix}`; // Ensure the final value is set correctly
        }
      };

      requestAnimationFrame(animate);
    },
  },
});
</script>
