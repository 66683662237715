<template>
  <section class="tw-h-[65vh]">
    <div v-if="variants.v1 === variant" class="w-full">
      <div class="tw-absolute tw-inset-0">
        <NuxtImg
          :src="vars.backgroundImage"
          alt="Background Image"
          class="tw-h-full tw-w-full tw-object-cover tw-object-top"
        />
      </div>
      <div
        class="tw-absolute tw-inset-0 tw-flex tw-items-end tw-justify-center tw-bg-black tw-bg-opacity-20 lg:tw-justify-start"
      >
        <h1 class="tw-mb-28 tw-text-white lg:tw-mb-16 lg:tw-ml-28">
          {{ vars.titleText }}
        </h1>
      </div>
    </div>
    <div v-if="variants.v2 === variant" class="w-full">
      <div class="tw-absolute tw-inset-0">
        <NuxtImg
          :src="vars.backgroundImage"
          alt="Background Image"
          class="tw-h-full tw-w-full tw-object-cover tw-object-top"
        />
      </div>
      <div
        class="tw-absolute tw-inset-0 tw-opacity-50"
        :style="`background-color: var(--c-${vars.backgroundLayerColor})`"
      ></div>
      <h1 class="tw-relative tw-z-40 tw-text-white">
        {{ vars.titleText }}
      </h1>
    </div>
    <div v-if="variants.v3 === variant" class="w-full">
      <div class="tw-absolute tw-inset-0">
        <NuxtImg
          :src="vars.backgroundImage"
          alt="Background Image"
          class="tw-h-full tw-w-full tw-object-cover tw-object-top"
        />
      </div>
      <div
        class="tw-absolute tw-inset-0 tw-opacity-50"
        :style="`background-color: var(--c-${vars.backgroundLayerColor})`"
      ></div>
      <div
        v-html="vars.titleHtml"
        class="tw-relative tw-z-40 tw-text-white"
      ></div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'IntroSection',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
        v3: 'v3',
      },
    };
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
