<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      class="tw-relative tw-flex tw-w-full tw-flex-col tw-items-center tw-text-black lg:tw-max-w-none"
    >
      <h2>{{ vars.titleText }}</h2>
      <LibraryPopupsVideoPopup
        :videoUrl="currentVideoUrl"
        v-model:is-visible="isVisible"
      />
      <div
        v-html="vars.titleHtml"
        class="tw-mb-10 tw-mr-auto tw-flex tw-flex-wrap tw-items-start tw-justify-start tw-gap-x-3 tw-text-start"
      ></div>
      <Swiper
        :modules="[SwiperPagination]"
        @slideChange="onSlideChange"
        :pagination="false"
        :space-between="7"
        effect="fade"
        :clickable="true"
        :breakpoints="{
          320: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 2,
          },
        }"
        @swiper="onSwiper"
        class="tw-w-full"
      >
        <SwiperSlide
          v-for="(review, index) in reviews"
          :key="`singletestimonials-review-${index}`"
          class="!tw-h-auto tw-px-2"
        >
          <div
            class="tw-flex tw-h-full tw-w-full tw-flex-col tw-bg-white tw-p-4 tw-duration-300 hover:tw-scale-[0.99] hover:tw-shadow-md"
            style="border-radius: var(--rounded)"
          >
            <div
              class="lg:tw-row tw-flex tw-h-full tw-flex-col tw-items-center tw-gap-5 tw-text-start"
            >
              <LibraryImage
                v-if="review.photo && !review.video"
                :src="review.photo"
                :alt="vars.titleText"
                class="tw-w-full"
                :h-ratio="4.5"
                :w-ratio="7"
                style="border-radius: var(--rounded-sm)"
              />
              <div
                v-if="review.photo && review.video"
                class="tw-relative tw-w-full"
                @click="(isVisible = true), (currentVideoUrl = review.video)"
              >
                <LibraryImage
                  :src="review.photo"
                  :alt="vars.titleText"
                  class="tw-w-full tw-cursor-pointer"
                  :h-ratio="4.5"
                  :w-ratio="7"
                  style="border-radius: var(--rounded-sm)"
                />
                <button
                  class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
                >
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 162 162"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="81" cy="81" r="81" fill="white" />
                    <polygon points="65,45 115,81 65,117" fill="black" />
                  </svg>
                </button>
              </div>
              <div class="tw-flex tw-h-full tw-flex-col tw-gap-4 lg:tw-h-auto">
                <div class="tw-flex tw-flex-col tw-gap-1">
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <svg
                      v-for="n in review.rating"
                      :key="`singletestimonials-star-${n}`"
                      width="24"
                      height="24"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                        fill="var(--c-secondary)"
                      />
                    </svg>
                  </div>
                  <h4 class="tw-mt-2">{{ review.title }}</h4>
                </div>
                <div
                  class="tw-flex tw-max-h-60 tw-flex-col tw-gap-2 tw-overflow-y-auto xl:tw-max-h-80"
                  v-html="review.description"
                ></div>
                <p class="tw-mt-auto !tw-font-bold">{{ review.author }}</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div>
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v3"
          :can-paginate-back="canPaginateBack"
          :can-paginate-next="canPaginateNext"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'VideoTestimonials',

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      currentVideoUrl: '',
      isVisible: false,
    };
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onSlideChange() {
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
  mounted() {
    this.onSlideChange();
  },
  computed: {
    reviews() {
      return this.groupedVariables.reviews;
    },
  },
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--c-secondary);
}
::-webkit-scrollbar-thumb:window-inactive {
  display: none;
}
</style>
